<mat-card class="mat-card">
  <form class="location-inputs" [formGroup]="form" (ngSubmit)="submit()">
    <maja-location-input
      class="location-input"
      label="From"
      controlName="from"
      [options]="fromOptions$ | async"
      [parent]="form"
    ></maja-location-input>
    <maja-location-input
      class="location-input"
      label="Destination"
      controlName="to"
      [options]="toOptions$ | async"
      [parent]="form"
    ></maja-location-input>
    <maja-location-datepicker
      class="location-datepicker"
      label="Date"
      controlName="date"
      [parent]="form"
    ></maja-location-datepicker>
    <maja-button-with-spinner
      [isDisabled]="form.invalid"
      [isLoading]="isLoading"
    >
      <span button-label>
        Search
      </span>
    </maja-button-with-spinner>
  </form>
</mat-card>
