import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, UrlSerializer } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  flatMap,
  startWith,
  takeWhile
} from 'rxjs/operators';
import { Observable, of, pipe } from 'rxjs';
import moment from 'moment';

import {
  CountryCode,
  Location,
  LocationService,
  TimetableParam
} from '@maja/pact-services';

@Component({
  selector: 'maja-search-journey',
  templateUrl: './search-journey.component.html',
  styleUrls: ['./search-journey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchJourneyComponent implements OnDestroy, OnInit {
  @Input() host: null;

  form: FormGroup;
  fromOptions$: Observable<Location[]>;
  isLoading = false;
  toOptions$: Observable<Location[]>;

  private active = true;
  private filter = (search: string): Observable<Location[]> =>
    !!+search || search.length > 2
      ? this.locationService.searchLocations(
          CountryCode.Sweden,
          search,
          this.host
        )
      : of([]);

  // tslint:disable-next-line:member-ordering
  private filterOperators = pipe(
    takeWhile(() => this.active),
    startWith(''),
    debounceTime(500),
    distinctUntilChanged(),
    flatMap(this.filter)
  );

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private router: Router,
    private serializer: UrlSerializer,
  ) {}

  ngOnDestroy() {
    this.active = false;
  }

  ngOnInit() {
    this.form = this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      date: [new Date(), Validators.required]
    });
    this.fromOptions$ = this.form.controls.from.valueChanges.pipe(
      this.filterOperators
    );
    this.toOptions$ = this.form.controls.to.valueChanges.pipe(
      this.filterOperators
    );
  }

  submit() {
    const { date, from, to } = this.form.value;
    const queryParams: TimetableParam = {
      arrivalCountryCode: to.countryCode,
      arrivalLocationId: to.locationId,
      departureCountryCode: from.countryCode,
      departureLocationId: from.locationId,
      departureTime: `${moment(date).format('YYYY-MM-DD')}T00:00:00`
    };
    const tree = this.router.createUrlTree([], { queryParams });
    window.open(
      `${this.host}${this.serializer.serialize(tree)}`,
      '_blank'
    );
  }
}
