import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Injector, DoBootstrap } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatSelectModule,
  MatToolbarModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';

import { BASE_API_URL } from '@maja/pact-services';
import { SharedComponentsModule } from '@maja/shared-components';

import { SearchJourneyComponent } from './containers/search-journey/search-journey.component';
import { COMPONENTS } from './components';

@NgModule({
  declarations: [...COMPONENTS, SearchJourneyComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatToolbarModule,
    ReactiveFormsModule,
    RouterModule.forRoot([]),
    SharedComponentsModule
  ],
  providers: [{ provide: BASE_API_URL, useValue: '/api' }],
  exports: [SearchJourneyComponent],
  entryComponents: [SearchJourneyComponent]
})
export class SearchJourneyModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const element = createCustomElement(SearchJourneyComponent, {
      injector: this.injector
    });
    customElements.define('maja-search-journey', element);
  }
}
