<div class="location-input" [formGroup]="parent">
  <mat-form-field class="input" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-icon class="mat-icon" matPrefix>sentiment_very_satisfied</mat-icon>
    <input
      type="text"
      matInput
      [formControlName]="controlName"
      [placeholder]="label"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayLocationName"
    >
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option.locationName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
