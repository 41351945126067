import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Location } from '@maja/pact-services';

@Component({
  selector: 'maja-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss']
})
export class LocationInputComponent {
  @Input() label: string;
  @Input() controlName: string;
  @Input() options: Location[];
  @Input() parent: FormGroup;

  displayLocationName(location?: Location): string | undefined {
    return location ? location.locationName : undefined;
  }
}
