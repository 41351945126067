import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CountryCode, Location, Locations } from './location.model';
import { HttpService } from './../http.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  static readonly BASE_URL = '/locations';

  constructor(private http: HttpService) {}

  searchLocations(
    countryCode: CountryCode,
    searchPhrase: string,
    host: string,
  ): Observable<Location[]> {
    const params = {
      countryCode,
      searchPhrase
    };
    return this.http.get<Locations>(LocationService.BASE_URL, params, host).pipe(
      map((response: Locations) => response.locations),
      catchError(_ => of([]))
    );
  }
}
