import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatProgressSpinnerModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ButtonComponent } from './components/button/button.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonWithSpinnerComponent } from './components/button-with-spinner/button-with-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  exports: [ButtonComponent, SpinnerComponent, ButtonWithSpinnerComponent],
  declarations: [ButtonComponent, SpinnerComponent, ButtonWithSpinnerComponent]
})
export class SharedComponentsModule {}
