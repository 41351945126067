import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SearchJourneyModule } from './lib/search-journey.module';

import 'zone.js';

export { SearchJourneyModule };

platformBrowserDynamic()
  .bootstrapModule(SearchJourneyModule)
  .catch(err => console.error(err));
