<div class="location-input" [formGroup]="parent">
  <mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-datepicker-toggle
      matPrefix
      [for]="myDatepicker"
    ></mat-datepicker-toggle>
    <input
      matInput
      [formControlName]="controlName"
      [min]="minDate"
      [matDatepicker]="myDatepicker"
    />
    <mat-datepicker #myDatepicker></mat-datepicker>
  </mat-form-field>
</div>
