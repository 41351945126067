import { Component, Input } from '@angular/core';

@Component({
  selector: 'maja-button-with-spinner',
  templateUrl: './button-with-spinner.component.html',
  styleUrls: ['./button-with-spinner.component.scss']
})
export class ButtonWithSpinnerComponent {
  @Input() buttonCssClass?: string;
  @Input() spinnerDiameter? = 37;
  @Input() isLoading = false;
  @Input() isDisabled = false;
}
