import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'maja-location-datepicker',
  templateUrl: './location-datepicker.component.html',
  styleUrls: ['./location-datepicker.component.scss']
})
export class LocationDatepickerComponent implements OnInit {
  @Input() label: string;
  @Input() controlName: string;
  @Input() parent: FormGroup;
  minDate = new Date();

  constructor() {}

  ngOnInit() {}
}
