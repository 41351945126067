import { Injectable, Inject } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers: HttpHeaders;

  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders({
      Accept: '*/*',
      'Content-Type': 'application/json'
    });
  }

  get<T>(
    url: string,
    params = {},
    host: string | null = null
  ): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http
      .get(this.getUrl(url, host), {
        headers: this.headers,
        params: httpParams
      })
      .pipe(catchError(error => this.handleError(error, 'get', url)));
  }

  post<T>(url: string, body: any, host: string | null = null): Observable<any> {
    return this.http
      .post(this.getUrl(url, host), body, { headers: this.headers })
      .pipe(catchError(error => this.handleError(error, 'post', url)));
  }

  put<T>(url: string, body: any, host: string | null = null): Observable<any> {
    return this.http
      .put(this.getUrl(url, host), body, { headers: this.headers })
      .pipe(catchError(error => this.handleError(error, 'post', url)));
  }

  patch<T>(
    url: string,
    body: any,
    host: string | null = null
  ): Observable<any> {
    return this.http
      .patch(this.getUrl(url, host), body, { headers: this.headers })
      .pipe(catchError(error => this.handleError(error, 'post', url)));
  }

  delete(url: string, host: string | null = null): Observable<any> {
    return this.http
      .delete(this.getUrl(url, host), { headers: this.headers })
      .pipe(catchError(error => this.handleError(error, 'post', url)));
  }

  private getUrl(url: string, host: string | null) {
    return host ? `${host}/${this.baseUrl}${url}` : `${this.baseUrl}${url}`;
  }

  private handleError = (
    error: HttpErrorResponse,
    method: string,
    url: string
  ): Observable<Error> =>
    throwError(
      new Error(`Http method: ${method}, url: ${url}, error: ${error}`)
    );
}
