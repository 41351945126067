import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpService } from './../http.service';
import { Timetable, TimetableParam, Timetables } from './timetable.model';

@Injectable({
  providedIn: 'root'
})
export class TimetableService {
  static readonly BASE_URL = '/timetable';

  constructor(private http: HttpService) {}

  getTimetable(
    params: TimetableParam,
    host: string | null = null
  ): Observable<Timetable[]> {
    return this.http
      .get<Timetables>(TimetableService.BASE_URL, params, host)
      .pipe(
        map((response: Timetables) => response.timetables),
        catchError(_ => of([]))
      );
  }
}
