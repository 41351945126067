export interface Location {
  producerCode: number;
  locationId: number;
  locationNameShort: string;
  locationName: string;
  zoneLocation: number;
  zoneLocationProducer: number;
  locationCode: string;
  iataCode: string;
}

export interface Locations {
  locations: Location[];
}

export interface LocationModel {
  locationId: number;
  producerCode: number;
}

export enum CountryCode {
  Sweden = '74'
}
