import { Component, Input } from '@angular/core';

@Component({
  selector: 'maja-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() cssClass?: string;
  @Input() isDisabled = false;
}
